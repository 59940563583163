import { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { deleteCookie, getSessionToken } from '../utils/session';

export const authContext = createContext({});

const AuthProvider = ({ children }: any) => {
	const [ auth, setAuth ] = useState<any>({ loading: true, token: null });

	const logout = () => {
		deleteCookie();
		setAuth({ token: 'loggedout' });
	}

	useEffect(() => {
		async function getUser() {
			const token = getSessionToken();
			setAuth({ loading: false, token });
		}
		getUser();
	}, []);

	return (
		<authContext.Provider value={{ ...auth, logout }}>
			{children}
		</authContext.Provider>
	);
}

const useAuth: any = () => useContext(authContext);

AuthProvider.propTypes = {
	children: PropTypes.node
}

export { AuthProvider, useAuth }