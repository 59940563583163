import { Component, ReactNode } from 'react';
import '../assets/App.css';
import { AuthProvider } from '../providers/Auth';
import Home from './Home';

export class App extends Component {
	state = {
		isScrolled: false,
		navbarOpen: false
	}

	render(): ReactNode {
		return (
			<AuthProvider>
				<Home />
			</AuthProvider>
		)
	}
}
