import { ReactElement, useEffect, useState } from 'react';
import logo from '../assets/logo.png';
import '../assets/App.css';
import { useAuth } from '../providers/Auth';

export default function Home() {
	const { token, logout } = useAuth();
	const [ user, setUser ] = useState<Profile>({ id: '', name: '', avatarUrl: '', email: '', accent: 0, bannerUrl: '', verified: false, premiumType: 0, flags: 0, publicFlags: 0 });
	const [ hover, setHover ] = useState(false);
	const { name, avatarUrl, accent } = user;

	function doLogout() {
		logout();
		window.location.reload()
	}

	useEffect(() => {
		async function getUser() {
			const res = await fetch('/user', {
				headers: { 'Authorization': `Bearer ${token}` }
			});
			const data = await res.json();
			setUser(data);
		}
		if(token) getUser();
	}, [token]);

	let logButton: ReactElement<any, any>;
	if(name) logButton = <LogOutButton onClick={doLogout} />
	else logButton = <LoginButton />

	const logoStyle = (color?: number) => {
		return {
			logo: {
				height: '10em',
				padding: '0em',
				'will-change': 'filter',
				'border-radius': '50%'
			},
			logoHover: {
				filter: `drop-shadow(0 0 2em ${decimalToRgb(color || 11222271)})`
			}
		}
	};

	return (
		<div id='main'>
			<img src={avatarUrl ? `${avatarUrl}?size=512` : logo} onMouseEnter={() => {
				setHover(true);
			}} onMouseLeave={() => {
				setHover(false);
			}} style={{ ...logoStyle().logo, ...(hover ? logoStyle(accent).logoHover : null)}} alt={name || 'logo'} />
			<h1>{name ? `hi ${name}! i like your avatar 😍` : 'hey stranger! welcome 😎'}</h1>
			<div className='card'>
				<a href='https://discord.gg/f6v2hDSzHR'><button type='button' className='discordBtn'>🤔 my discord</button></a>
				{logButton}
			</div>
			<p className='mid'>portfolio of work coming soon. watch this space...</p>
			<p className='bottom'>need work done? msg me on X (<a href='https://x.com/thesluuuudge' target='blank'>@thesluuuudge</a>) or Bluesky (<a href='https://bsky.app/profile/slud.ge' target='blank'>@slud.ge</a>)</p>
		</div>
	);
}

function LoginButton() {
	return (
		<a href='/auth/login'><button type='button' className='loginBtn'>who are you? 🤭</button></a>
	)
}

function LogOutButton(props: any) {
	return (
		<button type='button' onClick={props.onClick} className='logoutBtn'>run away? 🥺</button>
	)
}

function decimalToRgb(dec: number) {
	const r = (dec & 0xff0000) >> 16;
	const g = (dec & 0x00ff00) >> 8;
	const b = (dec & 0x0000ff);

	return `rgb(${r}, ${g}, ${b})`
}